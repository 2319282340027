import styled from 'styled-components';

export const CommonBtn= styled.a`
    background: #ff9900;
    border-radius: 3px;
    color: #fff;
    padding: 8px 15px;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    line-height: 30px;
    max-width: 250px;
    margin: auto;
    :hover{
        background:#e88d04;
    }
`;


