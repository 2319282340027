import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 100;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 300;
        color:#424345;
    }
    a{
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 300;
    }
    P{
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 300;
    }
`;

export default GlobalStyle;