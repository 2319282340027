import React from "react"
import "../components/layout.css"
import NotFound from "../containers/PageNotFound/NotFound"
import GlobalStyle from "../containers/PageNotFound/NotFound/global-styles"
import Fonts from "../containers/PageNotFound/NotFound/fonts"

const NotFoundPage = () => (
    <div>
        <Fonts />
        <GlobalStyle />
        <NotFound/>
    </div>
)

export default NotFoundPage;
